/* ========== GENERAL LENDER COMPONENT STYLES ========== */
.lender {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.lender__description {
  margin: 0;
  margin-bottom: 20px;
}

/* ========== TIPS SECTION STYLES ========== */
.lender__tips {
  font-family: "Open Sans", sans-serif;
  font-display: swap;
  width: 415px;
  height: 145px;
  background-color: #f8f9ff;
  color: var(--dashboard-secondary-color);
  font-size: 12px;
  padding: 5px 12px;
  border-radius: 10px;
}

.lender__tips-title {
  font-weight: 700;
  font-style: italic;
}

.lender__tips-list {
  padding: 0;
  padding-left: 20px;
}

.lender__tips-item {
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 17px;
  padding-right: 25px;
}

/* ========== CHAT SUGGESTION STYLES ========== */
.lender__chat-suggestions {
  margin-top: 15px;
  font-family: "Open Sans", sans-serif;
  font-display: swap;
  color: var(--dashboard-secondary-color);
  font-size: 13px;
  display: flex;
  gap: 8px;
}

.lender__chat-suggestion {
  background-color: #f8f9ff;
  border-radius: 10px;
  padding: 12px 20px;
  width: fit-content;
  cursor: pointer;
  border: none;
  color: var(--dashboard-secondary-color);
  font-size: 13px;
}

.lender__chat-suggestion:hover {
  opacity: 0.8;
}

/* ========== SHORTCUT LINKS STYLES ========== */
.lender__shortcuts,
.lender__cash-option {
  margin-top: 20px;
  font-size: 11px;
  color: var(--dashboard-tertiary-color);
  text-decoration: underline;
  cursor: pointer;
}

.lender__shortcut-link {
  margin-bottom: 5px;
}

.lender__shortcut-link:hover {
  opacity: 0.8;
}

/* ========== TABLE STYLES ========== */
.lender__table-container {
  overflow-x: auto;
  margin-top: 20px;
}

.lender__table {
  border-collapse: separate;
  border-spacing: 0;
  color: black;
  font-size: 13px;
}

.lender__table-header {
  text-align: left;
  padding: 8px 15px;
  font-weight: 700;
  border-bottom: 0.5px solid #000000;
}

.lender__cell {
  padding: 15px;
  vertical-align: middle;
  border-bottom: 0.5px solid var(--dashboard-tertiary-color);
}

/* Cell-specific styles */
.lender__cell--logo {
  width: 160px;
}

.lender__logo {
  max-height: 50px;
  width: 100%;
  max-width: 80px;
  object-fit: contain;
  display: block;
}

.lender__cell--apr {
  font-weight: 600;
  font-size: 20px;
  width: 150px;
}

.lender__cell--agent {
  width: 300px;
}

.lender__cell--action {
  width: 110px;
}

/* ========== AGENT INFORMATION STYLES ========== */
.lender__agent {
  display: flex;
  align-items: center;
  gap: 12px;
}

.lender__agent-photo-wrapper {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;
}

.lender__agent-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lender__agent-name {
  margin-bottom: 2px;
}

.lender__agent-name,
.lender__agent-email,
.lender__agent-phone {
  font-weight: 400;
  font-size: 11px;
}

.lender__agent-email,
.lender__agent-phone {
  color: #8c8c8c;
}

/* ========== ACTION BUTTON STYLES ========== */
.lender__action-button {
  background-color: var(--dashboard-primary-color);
  color: white;
  border: none;
  border-radius: 10px;
  padding: 10px 15px;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.lender__action-button:hover {
  opacity: 0.8;
}

/* ========== CTA BUTTON STYLES ========== */
.lender__cta {
  margin-top: 70px;
}

.lender__cta-button {
  background-color: var(--dashboard-primary-color);
  color: white;
  border: none;
  padding: 11px 13px;
  border-radius: 10px;
  cursor: pointer;
}

.lender__cta-button:hover {
  opacity: 0.8;
}
