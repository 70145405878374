/* ========== AI CHAT VIEWER STYLES ========== */
.ai-comparable-sale {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  height: 100%s;
}

.ai-comparable-sale__container {
  background-color: #22242a;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 90%;
  display: flex;
  flex-direction: column;
}

/* ========== CHAT HEADER STYLES ========== */
.ai-comparable-sale__header {
  padding: 18px 30px;
  display: flex;
  gap: 10px;
}

.ai-comparable-sale__title {
  display: flex;
  align-items: center;
}

.ai-comparable-sale__sparkle-icon {
  width: 22px;
}

.ai-comparable-sale__exit {
  font-size: 30px;
  font-weight: 300;
  margin-left: auto;
  cursor: pointer;
}

/* ========== CONTENT STYLES ========== */
.ai-comparable-sale__content {
  display: flex;
  padding: 0 30px;
  gap: 16px;
}

.ai-comparable-sale__analysis-container {
  width: 45%;
  position: relative;
}

.ai-comparable-sale__map-container {
  width: 50%;
}

/* ========== COMP RESULTS STYLES ========== */
.comp-results {
  position: absolute;
  max-height: 600px;
  overflow-y: scroll;
  box-sizing: border-box;
  border-radius: 4px;
}

.comp-results-content {
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 10px;
  background-color: #24293f;
  color: #ffffff;
}

.comp-results-content th,
.comp-results-content td {
  border: 1px solid #e0e0e0;
  padding: 8px 12px;
  text-align: left;
  font-weight: normal;
}

.comp-results-content th {
  background-color: #24293f;
  color: white;
  font-weight: bolder;
}

.comp-results-content tr:nth-child(even) {
  background-color: #24293f;
  color: white;
}

.comp-results-content h2,
.comp-results-content h3,
.comp-results-content h4 {
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}

.comp-results-content p {
  margin-bottom: 1rem;
}

/* ========== MAP STYLES ========== */
.ai-comparable-sale__map-container {
    width: 100%;
    max-width: 400px;
}
.ai-comparable-sale__map {
    width: 400;
    height: auto;
}