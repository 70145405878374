.phone_verification__container {
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 70px);
  text-align: center;
  padding-top: 0px;
  font-family: "Outfit", sans-serif;
  font-display: swap;
}

.phone_verification__form {
  background-color: white;
  border-radius: 8px;
  padding: 30px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.phone_verification__title {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  margin-top: 0;
  padding-top: 0;
}

.phone_verification__subtitle {
  margin-bottom: 10px;
}

.phone_verification__phone-number {
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.phone_verification__inputs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.phone_verification__input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: white;
}

.phone_verification__input:focus {
  outline: none;
  border-color: #3654ff;
}

.phone_verification__alert {
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: bold;
}

.phone_verification__alert--phone-resent-success {
  color: #4caf50;
}

.phone_verification__alert--phone-verification-error {
  color: #f44336;
}

.phone_verification__button-container {
  margin-bottom: 20px;
}

.phone_verification__button {
  background-color: #3654ff;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  font-family: "Outfit", sans-serif;
  font-display: swap;
}

.phone_verification__button:disabled {
  background-color: grey;
  color: rgb(206, 206, 206);
  cursor: not-allowed;
}

.phone_verification__resend-container {
  margin-top: 10px;
}

.phone_verification__resend-button {
  background: none;
  color: #3654ff;
  font-size: 14px;
  border: none;
  cursor: pointer;
}

.phone_verification__resend-button:hover {
  text-decoration: underline;
}

/* Styling for Icon (add an image before the title) */
.phone_verification__icon {
  height: 200px;
}
