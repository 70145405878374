.send_verification_code_container {
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.send_verification_code_formWrapper {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.send_verification_code_heading {
font-family: "Outfit", sans-serif;
  font-display: swap;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 20px;
}

.send_verification_code_subtitle {
font-family: "Outfit", sans-serif;
  font-display: swap;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 40px;
}

.send_verification_code_inputGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.send_verification_code_inputField {
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px 15px;
  width: 100%;
font-family: "Outfit", sans-serif;
  font-display: swap;
  font-size: 14px;
  box-sizing: border-box;
}

.send_verification_code_inputField input.PhoneInputInput {
  border: None;
}
.send_verification_code_inputField input.PhoneInputInput:focus {
  outline: none;
}

.send_verification_code_inputLabel {
font-family: "Outfit", sans-serif;
  font-display: swap;
  font-size: 13px;
  text-align: left;
}

.send_verification_code_button {
  background-color: #3654ff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  padding: 12px 0;
  width: 100%;
  cursor: pointer;
font-family: "Outfit", sans-serif;
  font-display: swap;
  font-weight: bold;
  font-size: 16px;
  margin-top: 20px;
}

.send_verification_code_button:disabled {
  background-color: grey;
  color: rgb(206, 206, 206);
}

.send_verification_code_alert {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  min-height: 20px; /* Maintain consistent height */
  visibility: hidden; /* Default to hidden */
}

.send_verification_code_alert--visible {
  visibility: visible; /* Show only when there's an error */
}

.send_verification_code_spinner {
  width: 30px;
  height: 30px;
  border: 4px solid rgba(255, 255, 255, 0.3); /* light color */
  border-top: 4px solid #3654ff; /* Blue color */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

.email_verification__email_icon {
  height: 80px;
  width: 100px;
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
