.home-search {
  position: relative;
  font-family: "Outfit", sans-serif;
  font-display: swap;
}

.home-search-results-number {
  padding-left: 5px;
}

/* Container layout for split view */
.home-search-container {
  display: flex;
  height: calc(100vh - 70px - 40px - 68px - 80px);
  margin-top: 10px;
}

/* Left side - home list */
.home-search-main-content {
  position: relative;
  padding: 20px;
  padding-left: 3px;
  padding-top: 0px;
  width: 350px; /* Fixed width for home list */
  overflow-y: auto;
  box-sizing: content-box;
  flex-shrink: 0;
}

.home-search-main-content::-webkit-scrollbar {
  background: transparent;
  width: 0px;
}

.home-search-input {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 250px;
  font-family: "Outfit", sans-serif;
  font-display: swap;
}
.home-search-input::placeholder {
  font-family: "Outfit", sans-serif;
  font-display: swap;
}

.home-search-list {
  max-width: 330px;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-top: 0px;
  list-style: none;
  flex-direction: column;
}

.home-search-card {
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  background: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.2s ease-in-out, transform 0.1s ease-in-out;
  cursor: pointer;
}

.home-search-card:hover {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.home-search-card.selected {
  border: 2px solid #3654ff;
  box-shadow: 0px 3px 8px rgba(54, 84, 255, 0.3);
}

.home-search-card-image {
  width: 100%;
  height: 200px;
  background: #ddd; /* Placeholder */
  object-fit: fill;
}

.home-search-card-image-loading {
  width: 100%;
  height: 200px;
  background: #f6f7f8; /* Light gray base color */
  position: relative;
  overflow: hidden;
}

.home-search-card-image-loading::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(143, 143, 143, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(194, 194, 194, 0.395) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer-img 2s infinite;
}

@keyframes shimmer-img {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

/* Details section */
.home-search-card-details {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.home-search-card-price {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}

/* Estimated tax */
.home-search-card-rebate {
  font-size: 12.5px;
  font-weight: 350;
  color: white;
  letter-spacing: -0.3px;
  background-color: black;
  border-radius: 7.5px;
  padding: 7px 9px;
  text-align: center;
  margin-top: -4.5px;
  justify-content: center;
}

/* Info section */
.home-search-card-info {
  font-size: 14px;
  color: #444;
}

/* Address styling */
.home-search-card-address {
  font-size: 14px;
  color: #777;
}

.home-search-input {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 14px;
  width: 500px;
  height: 24px;
  font-size: 14px;
  margin-top: -65px;
}

.home-search-input:focus {
  outline: none;
}

/* Pagination container */
.home-search-pagination {
  max-width: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Space between buttons and page number */
  margin-top: 20px; /* Add space above the pagination */
  font-family: "Outfit", sans-serif;
  font-display: swap;
}

/* Pagination buttons */
.home-search-pagination button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 10px;
  padding: 6px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  font-family: "Outfit", sans-serif;
  font-display: swap;
}

.home-search-pagination button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

/* Hover effect for buttons */
.home-search-pagination button:not(:disabled):hover {
  background-color: #0056b3; /* Darker blue on hover */
  transform: translateY(-2px); /* Slight lift effect */
}

/* Page number display */
.home-search-pagination span {
  font-size: 18px;
  color: #333;
  font-weight: bold;
}

.home-search-list.loading .home-search-card {
  background-color: #f0f0f0;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.home-search-list.loading .home-search-card.skeleton::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0 0%, #e0e0e0 50%, #f0f0f0 100%);
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}

/* When loading, apply the shimmer effect to the entire list */
.home-search-list.loading .home-search-card-price,
.home-search-list.loading .home-search-card-info,
.home-search-list.loading .home-search-card-address {
  width: 100%;
}

/* ======= New styles for home detail view ======= */

/* Right side - detail view */
.home-search-detail {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

/* Header with home info */
.home-search-detail-header {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background-color: #f2f3f4;
  border-radius: 8px;
  margin-bottom: 12.5px;
  margin-top: -30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.home-search-detail-price {
  font-size: 18px;
  font-weight: bold;
  margin-right: 20px;
}

.home-search-detail-info {
  font-size: 16px;
  margin-right: 20px;
}

.home-search-detail-address {
  font-size: 14px;
  color: #555;
  flex-grow: 1;
  text-align: right;
}

/* Photo viewer */
.home-search-photo-viewer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 12.5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-search-photo {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.home-search-photo-loading,
.home-search-no-photos {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 18px;
  color: #666;
}

.home-search-photo-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10;
  transition: background-color 0.3s;
}

.home-search-photo-nav:hover {
  background-color: rgba(255, 255, 255, 0.9);
}

.home-search-photo-nav.prev {
  left: 15px;
}

.home-search-photo-nav.next {
  right: 15px;
}

.home-search-photo-counter {
  position: absolute;
  bottom: 15px;
  right: 15px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 14px;
}

/* Action buttons */
.home-search-action-buttons {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
}

.home-search-tour-btn,
.home-search-offer-btn {
  padding: 8px 25px;
  font-size: 16px;
  font-weight: 550;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  font-family: "Outfit", sans-serif;
  font-display: swap;
}

.home-search-tour-btn {
  background-color: white;
  color: #3654ff;
  border: 2px solid #3654ff;
}

.home-search-tour-btn:hover {
  background-color: rgba(54, 84, 255, 0.05);
  transform: translateY(-2px);
}

.home-search-offer-btn {
  background-color: #3654ff;
  color: white;
  border: 2px solid #3654ff;
  font-weight: 400;
}

.home-search-offer-btn:hover {
  background-color: #2a43cc;
  transform: translateY(-2px);
}

.home-search-offer-btn.loading {
  background-color: #d6d6d6;
  border: 2px solid #d6d6d6;
  color: #3e3e3e;
}
