/* ========== GENERAL LANDING PAGE STYLES ========== */
.landing {
  height: 100vh;
}

.landing-hero__content {
  z-index: 1;
  color: white;
}

/* ========== HERO STYLES ========== */
/* Hero Image */
.landing-hero {
  width: 100%;
  position: relative;
  height: 610px;
  /* height: 100vh; */
  /* max-height: 540px; */
  position: relative;
  background-image: url("../../../assets/landing_hero.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  text-align: center;
}

.landing-hero::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  z-index: 0;
}

/* Hero Content */
.landing-hero__content {
  position: relative;
  width: 100%;
  z-index: 1;
  color: white;
  text-align: center;
  /* margin-top: 100px; */
  margin-top: 170px;
}

/* Text Styles */
.landing-hero__subheadline {
  font-size: 20px;
  font-weight: 200;
  background: linear-gradient(
    90deg,
    rgba(50, 52, 45, 0.15) 0%,
    rgba(62, 91, 255, 0.15) 6%,
    rgba(62, 91, 255, 0.15) 93%,
    rgba(50, 52, 45, 0.15) 100%
  );
  width: 425px;
  padding: 0 26px;
  text-align: center;
  margin: 0 auto;
}

.landing-hero__subheadline strong {
  font-weight: 400;
}

.landing-hero__headline {
  margin-top: 8px;
  font-size: 45px;
  font-weight: 600;
}

.landing-hero__headline span {
  background: linear-gradient(90deg, #3654ff 0%, #7d90ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.landing-hero__description {
  font-weight: 300;
  font-size: 24px;
  margin-top: 1px;
}

/* Search Engine Styles*/
.landing-hero__search {
  background-color: #6e6e7680;
  width: 500px;
  height: 48px;
  border: 1px solid white;
  border-radius: 27.5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
  margin: 0 auto;
  margin-top: 28px;
}

.landing-hero__search-icon-container {
  width: 27px;
  height: 27px;
}

.landing-hero__search-icon {
  width: 27px;
  height: auto;
}

.landing-hero__search-input {
  width: 400px;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-display: swap;
  font-weight: 400;
  letter-spacing: 5%;
}

.landing-hero__search-input:focus {
  border: none;
  outline: none;
}

.landing__search-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.landing__search-icon {
  width: 8px;
  height: auto;
}

/* Zillow Container */
.landing-hero__zillow-rating-container {
  margin-top: 44px;
}

.landing-hero__zillow-rating {
  width: 65px;
}

/* Disclaimer */
.landing-hero__disclaimer {
  margin-top: 70px;
  font-family: "Open Sans", sans-serif;
  font-display: swap;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.2px;
}

/* Reviews */
.landing-hero__reviews {
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 50px;
  background-color: #ffffff33;
  display: flex;
  align-items: center;
}

.landing-hero__reviews-track::after {
  content: attr(data-duplicate);
  display: inline-block;
  white-space: nowrap;
}

.landing-hero__reviews-track {
  display: flex;
  gap: 54px;
  animation: hero-scroll 20s linear infinite;
  white-space: nowrap;
  width: max-content;
}

.landing-hero__review {
  font-size: 16px;
  font-weight: 600;
  color: white;
  letter-spacing: 0.2px;
  white-space: nowrap;
}

@keyframes hero-scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* ========== VALUE CARD STYLES ========== */
.landing-value-cards {
  margin-top: 70px;
}

.landing-value-cards__container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

/* Card Value Background Images */
.landing-value-card--unlike-trade-agent {
  background-image: url("../../../assets/landing_value_card_unlike_trad_agent.webp");
}

.landing-value-card--az-partner {
  background-image: url("../../../assets/landing_value_card_az.webp");
}

.landing-value-card--higher-value {
  background-image: url("../../../assets/landing_value_card_higher_value.webp");
}

/* Card Value */
.landing-value-card {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: gray;
  border-radius: 8px;
  width: 280px;
  height: 306px;
  text-align: center;
  padding: 0 20px;
  border-radius: 21px;
}

.landing-value-card__icon {
  width: 50px;
  height: 40px;
  margin-bottom: 44px;
  margin-top: 40px;
}

/* Card Value Text */
.landing-value-card__title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #fafafa;
}

.landing-value-card__description {
  font-size: 18px;
  font-weight: 300;
  color: #fafafa;
  line-height: 20px;
}

/* ========== REBATE STYLES ========== */
.landing-rebate {
  margin-top: 50px;
}

/* Title styles */
.landing-rebate__title {
  font-size: 35px;
  font-weight: 500;
  text-align: center;
}

/* Container Styles */
.landing-rebate__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 56px;
  gap: 35px;
}

/* Card Styles */
.landing-rebate__card {
  border-radius: 20px;
  border: 2px solid #f0f0f0;
  width: 600px;
  padding: 30px 26px;
}

.landing-rebate__content {
  display: flex;
  align-items: center;
  gap: 28px;
  width: 100%;
}

/* Detail Styles */
.landing-rebate__details {
  width: 100%;
  margin-right: 26px;
}

.landing-rebate__avatar {
  width: 80px;
  height: auto;
}

.landing-rebate__row {
  display: flex;
  justify-content: space-between;
}

.landing-rebate__label {
  font-size: 20px;
  font-weight: 400;
}

.landing-rebate__value {
  font-size: 20px;
  font-weight: bold;
}

.landing-rebate__label--agnsy-rebate,
.landing-rebate__value--agnsy-rebate {
  color: #3654ff;
}

/* Slider style */
.landing-rebate__slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  outline: none;
  background-color: #d9d9d9;
  outline: none;
}

.landing-rebate__slider::-webkit-slider-runnable-track {
  background: linear-gradient(
    to right,
    #6e83ff 0%,
    #3e5bff calc(var(--slider-percent, 50%) / 2),
    #6e83ff var(--slider-percent, 50%),
    #d9d9d9 var(--slider-percent, 50%),
    #d9d9d9 100%
  );
  border-radius: 5px;
  height: 6px;
}

.landing-rebate__slider::-moz-range-track {
  background: linear-gradient(
    to right,
    #6e83ff 0%,
    #3e5bff calc(var(--slider-percent, 50%) / 2),
    #6e83ff var(--slider-percent, 50%),
    #d9d9d9 var(--slider-percent, 50%),
    #d9d9d9 100%
  );
  border-radius: 5px;
  height: 6px;
}

.landing-rebate__slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #3e5bff;
  cursor: pointer;
  margin-top: -1px;
}

.landing-rebate__slider::-moz-range-thumb {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: none;
  background: #3e5bff;
  cursor: pointer;
  margin-top: 1px;
}

/* Access styles */
.landing-rebate__access {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin-top: 25px;
}

.landing-rebate__access span {
  font-size: 25px;
  font-weight: bold;
}

/* Info Styles*/
.landing-rebate__info {
  text-align: center;
  width: 350px;
}

.landing-rebate__info-description {
  font-family: "Open Sans", sans-serif;
  font-display: swap;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.2px;
  padding: 0 10px;
}

.landing-rebate__info-description span {
  color: #3654ff;
  font-weight: bold;
}

/* Button Styles */
.landing-rebate__button {
  margin-top: 35px;
  border: none;
  background: linear-gradient(
    90deg,
    #6e84ff 0%,
    #3654ff 20%,
    #3654ff 80%,
    #6e84ff 100%
  );
  border-radius: 30px;
  font-weight: 500;
  font-size: 18px;
  color: white;
  padding: 15px 22px;
}

/* ========== STEP STYLES ========== */
/* Step styles */
.landing-steps {
  margin-top: 90px;
}

/* Title styles */
.landing-steps__title {
  text-align: center;
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 50px;
}

.landing-steps__highlight {
  color: #3654ff;
}

.landing-steps__container {
  display: flex;
  justify-content: center;
  gap: 33px;
  flex-wrap: wrap;
}

/* Inidvidual Step styles */
.landing-step {
  position: relative;
  width: 310px;
  height: 350px;
  border-radius: 21px;
  border: 2px solid #f0f0f0;
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.landing-step__icon-container {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 40px;
}

.landing-step__number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 25px;
  font-weight: 600;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.landing-step__description {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: blur(7.5px);
  transition: filter 0.3 ease;
  transition: opacity 0.3s ease;
  font-size: 18px;
  color: #606060;
  line-height: 30px;
  letter-spacing: 1px;
  padding: 0 28px;
  width: 100%;
  box-sizing: border-box;
}

.landing-step__description-highlight {
  color: #3654ff;
}

/* Transition style*/
.landing-step__icon-container,
.landing-step__number {
  transition: opacity 0.4s ease, transform 0.4s ease;
}

.landing-step__description {
  transition: opacity 0.4s ease, transform 0.4s ease;
}

.landing-step:hover .landing-step__icon-container,
.landing-step:hover .landing-step__number {
  opacity: 0;
}

.landing-step:hover .landing-step__description {
  filter: blur(0);
}

.landing-step__icon-container {
  width: 50px;
  height: 40px;
}

/* Icon Styles */
.landing-step__icon--explore {
  width: 45px;
}

.landing-step__icon--offer {
  width: 30px;
}

.landing-step__icon--close {
  width: 32px;
}

.landing-step__icon {
  height: auto;
}

/* Explanation styles */
.landing-steps__explanation {
  text-align: center;
  margin-top: 43px;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 35px;
  color: #606060;
}

/* ========== CLIENT STYLES ========== */
.landing-clients {
  margin-top: 65px;
}

/* Title */
.landing-clients__title {
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 53px;
  text-align: center;
}

/* Client Cards */
.landing-clients__container {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.landing-client-card {
  position: relative;
  width: 300px;
  height: 400px;
  overflow: hidden;
  border-radius: 30px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.landing-client-card__image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Details style */
.landing-client-card__details {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  padding-bottom: 30px;
  background: linear-gradient(180deg, #0b0a070d 0%, #847d5e 100%);
  color: white;
  z-index: 1;
}

.landing-client-card__price {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 5px;
}

.landing-client-card__location {
  font-size: 13px;
  font-weight: 300;
}

.landing-client-card__rebate {
  position: absolute;
  bottom: 35px;
  right: 47px;
  font-size: 12px;
  font-weight: 300;
  background-color: rgba(255, 255, 255, 0.2);
  padding: 5px 10px;
  border-radius: 5px;
}

.landing-client-card__rebate strong {
  font-weight: 500;
}

/* ========== FOUNDER STYLES ========== */
.landing-founder {
  display: flex;
  align-items: center;
  margin: 0 auto;
  flex-direction: column;
  padding: 40px 40px;
  background: url("../../../assets/landing_founder_background.webp") no-repeat
    center center;
  background-size: cover;
  border-radius: 21px;
  color: white;
  width: 83%;
  max-width: 1000px;
  margin-top: 90px;
}

/* Title styles */
.landing-founder__title {
  text-align: center;
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 30px;
}

/* Content Styles */
.landing-founder__content {
  display: flex;
  text-align: left;
  width: 100%;
}

.landing-founder__name {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 35px;
}

.landing-founder__description {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.2px;
  margin-bottom: 30px;
  max-width: 630px;
}

/* Image style */
.landing-founder__image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 43px;
}
.landing-founder__image {
  width: 270px;
  height: auto;
}

/* Button style */
.landing-founder__button {
  margin-top: 35px;
  background-color: #fafafa33;
  border: none;
  color: white;
  border-radius: 30px;
  padding: 14px 27px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  letter-spacing: 2px;
}

/* ========== EXCLUSIVE LISTING STYLES ========== */
.exclusive-listings {
  overflow: hidden;
  width: 100%;
  margin-top: 86px;
}

/* Header styles */
.exclusive-listings__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
  padding: 0 94px;
}

.exclusive-listings__title-container {
  display: flex;
  flex-direction: column;
}

/* Title styles */
.exclusive-listings__title {
  font-family: "Outfit", sans-serif;
  font-display: swap;
  font-weight: 600;
  font-size: 28px;
  color: #222;
  margin: 0;
  margin-bottom: 5px;
}

.exclusive-listings__subtitle {
  font-family: "Outfit", sans-serif;
  font-display: swap;
  font-weight: 400;
  font-size: 16px;
  color: #555;
  margin: 0;
}

/* Control styles */
.exclusive-listings__controls {
  display: flex;
  gap: 10px;
}

.exclusive-listings__nav {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 1px solid #e0e0e0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #555;
  transition: all 0.2s ease;
}

.exclusive-listings__nav:hover {
  background-color: #f5f5f5;
  color: #333;
}

/* Carousel container */
.exclusive-listings__carousel {
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
}

/* Track that will slide horizontally */
.exclusive-listings__track {
  display: flex;
  transition: transform 0.5s ease;
  width: calc(100% * 2);
  gap: 0;
}

/* Each page containing 4 properties */
.exclusive-listings__page {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  flex-shrink: 0;
}

.exclusive-listing-card {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  background-color: white;
  width: 23vw;
}

.exclusive-listing-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.exclusive-listing-card__image-container {
  position: relative;
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.exclusive-listing-card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.exclusive-listing-card:hover .exclusive-listing-card__image {
  transform: scale(1.05);
}

.exclusive-listing-card__tag {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  font-family: "Outfit", sans-serif;
  font-display: swap;
  font-size: 12px;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 4px;
}

.exclusive-listing-card__price {
  font-family: "Outfit", sans-serif;
  font-display: swap;
  font-weight: 700;
  font-size: 18px;
  color: #222;
  padding: 12px 15px 4px;
}

.exclusive-listing-card__details {
  font-family: "Outfit", sans-serif;
  font-display: swap;
  font-weight: 400;
  font-size: 14px;
  color: #555;
  padding: 0 15px 4px;
}

.exclusive-listing-card__address {
  font-family: "Outfit", sans-serif;
  font-display: swap;
  font-weight: 400;
  font-size: 14px;
  color: #777;
  padding: 0 15px 15px;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .landing-listings-page {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .landing-listings-page {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .landing-listings-page {
    grid-template-columns: repeat(1, 1fr);
  }

  .landing-listings-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .landing-listings-controls {
    margin-top: 15px;
  }
}

/* ========== FOOTER STYLES ========== */
.footer-section {
  margin-top: 110px;
}

/* Footer Section */
.footer {
  background-image: url("../../../assets/landing_footer.webp");
  background-size: cover;
  color: #ffffff;
  padding: 60px 40px 30px;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

.footer__container {
  display: flex;
  justify-content: space-between;
  /* max-width: 1200px; */
  margin: 0 auto;
  padding: 0 53px 50px;
  box-sizing: border-box;
}

/* Left column with logo and tagline */
.footer__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer__logo {
  height: 40px;
  margin-bottom: 15px;
  filter: brightness(0) invert(1); /* Make the logo white */
}

.footer__tagline {
  font-family: "Outfit", sans-serif;
  font-display: swap;
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
  margin-bottom: 25px;
}

.footer__button {
  background-color: #fafafa33;
  color: white;
  font-family: "Outfit", sans-serif;
  font-display: swap;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 2px;
  padding: 15px 22px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.footer__button:hover {
  background-color: #3048cc;
}

/* Navigation columns */
.footer__nav {
  display: flex;
  gap: 100px;
}

.footer__nav-column {
  display: flex;
  flex-direction: column;
}

.footer__nav-title {
  font-family: "Outfit", sans-serif;
  font-display: swap;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 12px;
  letter-spacing: 2px;
}

.footer__nav-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.footer__nav-list-item {
  margin-bottom: 12px;
}

.footer__nav-link {
  font-family: "Outfit", sans-serif;
  font-display: swap;
  font-size: 15px;
  font-weight: 400;
  color: #fafafa;
  text-decoration: none;
  transition: color 0.2s ease;
  letter-spacing: 2px;
}

.footer__nav-link:hover {
  color: #ffffff;
}

/* Social media links */
.footer__social {
  display: flex;
  gap: 15px;
}

.footer__social-link {
  color: #999999;
  transition: color 0.2s ease;
}

.footer__social-link:hover {
  color: #ffffff;
}

/* Notice */
.footer__notice {
  text-align: center;
  font-family: "Outfit", sans-serif;
  font-display: swap;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 2px;
  color: #ffffff;
  max-width: 1200px;
  margin: 0 auto;
}

.footer__notice--DRE {
  padding-top: 4px;
}

/* Responsive adjustments */
@media (max-width: 900px) {
  .footer__container {
    flex-direction: column;
    gap: 40px;
  }

  .footer__nav {
    flex-wrap: wrap;
    gap: 40px;
  }
}

@media (max-width: 600px) {
  .footer {
    padding: 40px 20px 20px;
  }

  .footer__nav {
    flex-direction: column;
    gap: 30px;
  }

  .footer__nav-column {
    width: 100%;
  }
}
