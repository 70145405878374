.agnsy-header {
  height: 70px;
  z-index: 2;
  background-color: black;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.agnsy-header--landing {
  position: absolute;
  background-color: transparent;
}

.agnsy-header__content {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 52px;
  color: white;
}

.agnsy-header__text {
  text-decoration: none;
  color: white;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  letter-spacing: 3%;
}

.agnsy-header__left,
.agnsy-header__right {
  display: flex;
  align-items: center;
  gap: 35px;
}

.agnsy-header__logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}


.agnsy-header__logo-container a {
  display: flex; 
  justify-content: center;
  align-items: center; 
  height: 100%; 
  width: 100%; 
}
.agnsy-header__logo {
  width: 106px;
  height: auto;
}


/* .agnsy-header {
  height: 70px;
  background-color: #000000;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.agnsy-header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.agnsy-header__logo {
  height: 28px;
  margin-top: 9px;
  margin-left: 10px;
  width: auto;
}

.agnsy-header__text {
  color: #ffffff;
  font-size: 16px;
  text-decoration: none;
  margin-left: 40px;
  cursor: pointer;
}

.agnsy-header__right {
  display: flex;
  align-items: center;
}

.agnsy-header__my-homes,
.agnsy-header__create-account {
  color: #000000;
  background-color: #ffffff;
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 8px;
  text-decoration: none;
  margin-left: 40px;
  margin-right: 20px;
}  */
